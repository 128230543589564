import Loading from "./Loading/Loading"
import "./App.css";
import Header from "./Header/Header";
import PageFirst from "./PageFirst/PageFirst";
import PageSecond from "./PageSecond/PageSecond";
import PageThird from "./PageThird/PageThird";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });
  window.addEventListener("scroll", (event) => {
    const sizescroll = window.scrollY;
    if (sizescroll > 100) {
      document.getElementById("dark").classList.add("pagefirst-dark-active");
      document.getElementById("dark").style.opacity = sizescroll / 1000;
    } else {
      document.getElementById("dark").classList.remove("pagefirst-dark-active");
    }
  });
  return (
       <>
      {/* {
        isLoading ? "" : <Loading />
      } */}
        <Header />
        <PageFirst setIsLoading={setIsLoading} />
        <PageSecond />
        <PageThird />
        
      </>
    
  );
}

export default App;
