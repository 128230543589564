import React from "react";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";
import {HiOutlineMenu} from "@react-icons/all-files/hi/HiOutlineMenu";
import "./Header.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header> 
      <div className="header">
        <div className="header-left">
          <h2>Soran Ghafoori</h2>
        </div>
        <div className="header-middle">
          <ul>
            <li>
              <a href="#home">Home</a>{" "}
            </li>
            {/* <li>
              <a href="">Profile</a>{" "}
            </li> */}
            <li>
              <a href="#about">About</a>{" "}
            </li>
            <li>
              <a href="#contect">Contect</a>{" "}
            </li>
          </ul>
        </div>
        <div className="header-right">
          <a href="https://github.com/soranghafuori">
            <div style={{color:"white" ,textDecoration:"none"}}>
              <AiFillGithub style={{ fontSize: "20px", margin: "7px" , color:"white"}} />{" "}
              Github
            </div>
          </a>
          <a href="mailto:soranghafoori993@gmail.com">
            <div>
              <AiOutlineMail className="header-right-email" style={{fontSize:"30px"}}/>
            </div>
          </a>
        </div>
      </div>

      <div className="header-mobile">
        <Button className="header-mobile-button"  variant="primary" onClick={handleShow}>
          menu
        </Button>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Soran Ghafuori</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <div className="header-middle">
          <ul >
            <li>
              <a href="#home">Home</a>{" "}
            </li>
            <li>
              <a href="#about">About</a>{" "}
            </li>
            <li>
              <a  href="#contect">Contect</a>{" "}
            </li>
          </ul>
        </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  );
}
