import React from "react";
import "./PageFirst.css";
import { GrReactjs } from "@react-icons/all-files/gr/GrReactjs";
// import { DiJavascript1 } from "@react-icons/all-files/di/DiJavascript1";
import { TbBrandNextjs } from "react-icons/tb";
import TypeWriter from "react-typewriter";

export default function PageFirst({ setIsLoading }) {
  return (
    <div className="pagefirst" id="home">
      <div className="pagefirst-dark" id="dark"></div>
      <div className="pagefirst-left">
        <TypeWriter typing={1}>
          Hello ! <br />
          I`m <span style={{ color: "#E94560" }}>Soran Ghafoori</span>
          <br />
          I`m Web Developer Full Stack
          <br />
          You Can Click on Avatars To See Real Projects
          <br />
          And Scroll to Bottom , See My CV
          <br />
          Happy Like Me!!
        </TypeWriter>
        <a href="http://petromk.com/"> <GrReactjs className="pagefirst-right-icon-react" onClick={console.log("sakdjfn")}/></a>
        <a href="http://hali-kar.com/"><TbBrandNextjs className="pagefirst-right-icon-js" /></a>
      </div>
      <div className="pagefirst-right">
        {/* <a href="http://petromk.com/"> <GrReactjs className="pagefirst-right-icon-react" onClick={console.log("sakdjfn")}/></a>
        <a href="http://hali-kar.com/"><DiJavascript1 className="pagefirst-right-icon-js" /></a> */}
        
        <div className="pagefirst-right-circle">
          <img className="pagefirst-right-circle-img" src="./soranghafoori.png" alt="soran ghafoori" />
        </div>
      </div>
      {/* {setIsLoading(false)} */}
    </div>
  );
}
