import React from "react";
import "./PageSecond.css";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import TypeWriter from "react-typewriter";

export default function PageSecond() {
  const [showText , setShowText] = useState(false)
  window.addEventListener("scroll", (event) => {
    var sizescroll2 = window.scrollY;
    console.log(sizescroll2);
    if(sizescroll2 > 400){
      setShowText(true)
    }
  });
  return (
    <div className="pagesecond" id="about">
      <div className="pagesecond-box-text">
      {showText ? <TypeWriter className="pagesecond-text" typing={1}>
        <span>My</span>  nationality is <a href="https://en.wikipedia.org/wiki/Kurds">Kurdish</a> .
        {/* <br /><span>I</span> was born in <a href="https://en.wikipedia.org/wiki/Marivan">Marivan</a>  and now I live in <a href="https://en.wikipedia.org/wiki/Marivan">Marivan</a> . */}
        <br /><span>I</span> got my bachelor's degree in mathematics from the age of 18 to 22.
        <br /><span>I</span> am 22 years old and I have been working with full stack since I was 18
        years old.
        <br /><span>I</span> can work well with html, css, java script, react js, next js, bootstrap for front end.
        <br /><span>I</span> can work well with nodejs for back end.
        <br /> <span>I</span> have some familiarity with apache2 , api , typescript and databases mongodb. 
        <br /> At the moment you are reading this text, I am working and
        learning more.
        <br/> Because creating new ideas is what makes me happy.
      </TypeWriter> : <p></p>}
      </div>
    </div>
  );
}
