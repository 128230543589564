import React from "react";
import "./PageThird.css";
import { AiFillPhone } from "@react-icons/all-files/ai/AiFillPhone";
import { IoLogoWhatsapp } from "@react-icons/all-files/io/IoLogoWhatsapp";
import { FaTelegram } from "@react-icons/all-files/fa/FaTelegram";
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram";
import {MdEmail} from "@react-icons/all-files/md/MdEmail";
// import {} from "@react-icons/all-files/ai/"

export default function PageThird() {
  return (
    <div className="pagethird" id="contect">
      <div className="pagethird-logo-box">
        <a href="tel:+989933935303"><AiFillPhone className="pagethird-logo"/></a>
        <a href="https://wa.me/989210713257"><IoLogoWhatsapp className="pagethird-logo"/></a>
        <a href="https://t.me/soran_ghafoori"><FaTelegram className="pagethird-logo"/></a>
        <a href="https://www.instagram.com/soran.ghafoorii"><AiFillInstagram className="pagethird-logo"/></a>
        <a href="mailto:soranghafoori993@gmail.com"><MdEmail className="pagethird-logo"/></a>
      </div>
    </div>
  );
}
